var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import { TerminalPromotionStatus } from "@/models/Owner";
import DailySummary from "../../owner/eventGiveaway/components/DailySummary.vue";
const letter = ["A", "B", "C"];
const TIMEOUT = 30000;
const REMOVE_TIMEOUT = 60000;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            const data = yield post("/api/Service/PromotionIndex", { Id: "buyandgetfree" });
            // 前端重組資料
            for (const k of data.Kiosks) {
                const id = k.Kiosk.Id;
                if (data.TodayDeduced.includes(id))
                    k.TodayDeduced = true;
                const terminal = data.Terminals.find(t => t.KioskId == id);
                if (terminal && terminal.Status != TerminalPromotionStatus.Deactivated)
                    k.PromotionId = terminal.PromotionId;
                else
                    k.PromotionId = undefined;
                k.Processing = false;
            }
            this.data = data;
        });
    }
    info() {
        this.alert("<div class='text-left'><ol><li>設備「開通服務」即可開始設定方案</li><li>設備有設定方案才需計費</li></ol></div>", "設備方案", "warning");
    }
    edit(p) {
        this.$router.push("/service/bgf/edit/" + p.Id);
    }
    remove(p) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            if (!(yield this.confirm("原來套用該方案的設備狀態將改為「不使用」", "確定要刪除方案？", "warning")))
                return;
            try {
                yield post("/api/Service/RemovePromotion", { Id: p.Id }, REMOVE_TIMEOUT);
                this.data.Promotions.splice(this.data.Promotions.indexOf(p), 1);
                for (const k of this.data.Kiosks) {
                    if (k.PromotionId == p.Id)
                        k.PromotionId = undefined;
                }
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, undefined, "error");
            }
        });
    }
    setPromotion(k, id) {
        return __awaiter(this, void 0, void 0, function* () {
            // 先切換 id 以反應在 UI 上面
            if (k.PromotionId === id)
                return;
            const oldId = k.PromotionId;
            k.PromotionId = id;
            try {
                if (!k.TodayDeduced && id !== undefined) {
                    if (!(yield this.confirm('本台今日首次設定方案，將扣日費 <img src="/img/service_point.png" class="img-pt" /> 2 點', "溫馨提醒")))
                        throw true;
                }
                k.Processing = true;
                yield post("/api/Service/SetPromotion", { Id: k.Id, Name: "buyandgetfree", PromotionId: id }, TIMEOUT);
                k.Processing = false;
                if (id !== undefined) {
                    k.TodayDeduced = true;
                    yield this.alert(k.TodayDeduced ? "" : '已扣日費 <img src="/img/service_point.png" class="img-pt" /> 2 點', "已開通方案", "success");
                }
                else {
                    yield this.alert("此設備「明日」開始停止收費", "已關閉方案", "success");
                }
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "方案變更失敗", "error");
                k.PromotionId = oldId;
                k.Processing = false;
            }
        });
    }
    getLetter(i) {
        return letter[i];
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
default_1 = __decorate([
    Component({
        components: { DailySummary },
    })
], default_1);
export default default_1;
