
	import { Component, Inject, Vue } from "vue-property-decorator";

	import { EventType } from "@common/models/item";
	import Base from "../Base";
	import DailySummary from "../owner/eventGiveaway/components/DailySummary.vue";
	import ServicePoints from "./components/ServicePoints.vue";

	import type { ServicePointRecordItem, ServicePointRecordModel } from "@/models/Service";

	@Component({ components: { DailySummary, ServicePoints } })
	export default class extends Base {
		@Inject() public alert!: AlertMethod;

		protected data: ServicePointRecordModel | null = null;

		mounted(): void {
			this.load();
		}

		protected toggle(r: ServicePointRecordItem): void {
			Vue.set(r, "open", !r.open);
		}

		protected numberClass(r: ServicePointRecordItem): string {
			if(r.Event == EventType.Charge) return "text-success";
			if(r.PromotionTypeName == "活動送") return "text-warning";
			return "text-danger";
		}

		protected delta(r: ServicePointRecordItem): string {
			const delta = r.AfterAmount - r.BeforeAmount;
			return delta > 0 ? "+" + delta : delta.toString();
		}

		private async load() {
			this.data = null;
			this.data = await post<ServicePointRecordModel>("/api/Service/PointRecord");
		}

		protected add(): void {
			this.alert(`如須加值服務點，請洽 <a href="https://lin.ee/kUcmeAE" target="_blank">LeaPA 客服</a>。`);
		}
	}
