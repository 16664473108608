
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import DailySummary from "../../owner/eventGiveaway/components/DailySummary.vue";

	import type { PromotionModel } from "@/models/Owner";
	import type { BuyAndGetFreeModel } from "@/models/Service";

	const MINUTE_ONLY = /^\d\d:\d\d$/;

	type Model = PromotionModel<BuyAndGetFreeModel>;

	@Component({
		components: { DailySummary },
	})
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id?: string;

		protected data: Model | null = null;
		protected timeMode = false;

		protected get title(): string {
			return (this.id ? "編輯" : "新增") + "投Ｏ送Ｘ方案";
		}

		mounted(): void {
			if(this.id) {
				this.load();
			} else {
				this.data = {
					CodeName: "buyandgetfree",
					IsActive: true,
					Settings: {
						TimeLimit: 0,
					},
				} as Model;
			}
		}

		private async load() {
			this.data = null;
			this.data = await post<Model>("/api/Service/GetBGF", { Id: this.id });
			this.timeMode = this.data.Settings.StartHour != this.data.Settings.EndHour;
		}

		protected async submit(): Promise<void> {
			if(!this.data) return;

			// 這段修正是 C# 正確轉換 TimeOnly 格式所必須的
			const settings = this.data.Settings;
			if(!this.timeMode) {
				settings.StartHour = settings.EndHour = "00:00:00";
			}
			if(settings.StartHour.match(MINUTE_ONLY)) settings.StartHour += ":00";
			if(settings.EndHour.match(MINUTE_ONLY)) settings.EndHour += ":00";

			await post<boolean>("/api/Service/EditBGF", { ...this.data });

			this.$router.back();
		}
	}
