export enum EventType {
	Charge = 10,
	Purchase = 11,
	TransferIn = 12,
	ConcreteIn = 13,
	VirtualIn = 14,
	WriteOffCancel = 15,
	WriteOff = 20,
	Refund = 21,
	TransferOut = 22,
	ConcreteOut = 23,
	VirtualOut = 24,
}
